import React, {forwardRef, useEffect, useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormGroup,
    IconButton,
    Radio,
    RadioGroup,
    Slide,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {faEdit, faTrash,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import Loading from "../widgets/Loading";
import {getAllCustomers, saveNewCustomer} from "../services/CustomerService";
import {getAllPieces} from "../services/PieceService";
import {getInvoiceById, getMaxValue, previewPdf, putInvoice, saveInvoice} from "../services/InvoiceService";
import ReactSelectWidget from "../widgets/ReactSelectWidget";
import "./css/createinvoice.css"
import * as Yup from "yup";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from "@mui/lab";
import FilePreviewer from 'react-file-previewer';
import FileSaver from 'file-saver';
import {b64toBlob} from "../utils/utils";
import Message from "../widgets/Message";

function amount(item) {
    return item.totalPrice;
}

function sum(prev, next) {
    return prev + next;
}


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getNameInvoice = (value) => {
    if (value === 'proformat') {
        return "Proformat";
    } else if (value === 'delivery') {
        return "Livraison";
    } else {
        return "Facture";
    }
}


function CreateInvoice() {
    const {id} = useParams();

    const [dateInvoice, setDateInvoice] = React.useState(new Date());
    const [dateDelivery, setDateDelivery] = React.useState(null);

    const handleChangeDateInvoice = (value) => {
        setDateInvoice(value);
    };
    const handleChangeDateDelivery = (value) => {
        setDateDelivery(value);
    };

    const [pdfBase64, setPdfBase64] = useState(null);

    const [ninea, setNinea] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogModify, setOpenDialogModify] = useState(false);
    const [mode, setMode] = useState("nothing");

    const [datas, setDatas] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [pieces, setPieces] = useState([]);
    const [piecesCopy, setPiecesCopy] = useState([]);
    const [quantity, setQuantity] = useState([]);

    const [selectedArticle, setSelectedArticle] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState();
    const [selectedName, setSelectedName] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    //variable modify
    const [articleChange, setArticleChange] = useState();
    const [referenceChange, setReferenceChange] = useState();
    const [commentChange, setCommentChange] = useState();
    const [quantityChange, setQuantityChange] = useState();
    const [priceChange, setPriceChange] = useState();
    const [idChange, setIdChange] = useState();

    //Alert
    const [openAlert, setOpenAlert] = useState('none');
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    useEffect(() => {
        fetchCustomers().then();
        if (id) {
            fetchData(id).then(async (data) => {
                const pieces = await getAllPieces();
                /*   let uniqPieces = [
                       ...new Map(pieces
                           .map((item) => [item["name"], item])).values(),
                   ];
                   const filterPieces = uniqPieces.filter(ad => data.every(fd => fd.name !== ad.name));
                   setPieces(filterPieces);
                   setPiecesCopy(filterPieces);*/
                setPieces(pieces);
                setPiecesCopy(pieces);
            })
        } else {
            getAllPieces().then(async (data) => {
                let uniqueObjArray = [
                    ...new Map(data
                        .map((item) => [item["name"], item])).values(),
                ];
                setPieces(uniqueObjArray);
                setPiecesCopy(uniqueObjArray);
            });
        }

        fetchMaxValue().then();
    }, []);

    useEffect(() => {
        getQuantityPiece();
    }, [selectedArticle]);

    useEffect(() => {
        /* const result = pieces.filter(ad => datas.every(fd => fd.name !== ad.name));
         setPiecesCopy(result);*/
    }, [datas]);


    const fetchCustomers = async () => {
        const response = await getAllCustomers();
        if (response !== null) {
            let hintArray = []
            response.map(a => hintArray.push(a.name))
            setCustomers(response);
        }
    };


    const fetchData = async (id) => {
        const response = await getInvoiceById({id: id});
        if (response !== null) {
            await formikInvoice.setFieldValue("name", {name: response.nameCustomer});
            setSelectedName({name: response.nameCustomer});
            await formikInvoice.setFieldValue("type", {label: getNameInvoice(response.type), value: response.type});
            setSelectedType({label: getNameInvoice(response.type), value: response.type});
            const dataMapping = [];
            response.pieceList.map(piece => dataMapping.push(createData(piece.id, piece.name, piece.quantity, piece.price, piece.quantity * piece.price, piece.details, piece.reference, piece.mark)));
            setDatas(dataMapping);
            setInitialData(dataMapping);
            setMaxValue(Number(response.numbInvoice));
            setNinea(response.ninea);
            const utcSeconds = response?.createdAt?response?.createdAt:response?.updatedAt;
            const date = new Date(utcSeconds)
            setDateInvoice(date);
            await formikInvoice.setFieldValue("stringPrice", response.stringPrice);
            return dataMapping;
        }
        return null;
    }

    const fetchMaxValue = async () => {
        if (!id) {
            const response = await getMaxValue();
            if (response !== null) {
                setMaxValue(response.maxValue + 1);
            }
        }
    }


    const deleteArticle = (id) => {
        setDatas(datas.filter(e => e.id !== id));
    }

    function createData(id, name, quantity, price, totalPrice, details, reference, mark) {
        return {id, name, quantity, price, totalPrice, details, reference, mark};
    }

    const handleClose = () => {
        setOpen(false);
        setOpenDialogModify(false);
    }

    const handleCloseToSave = async () => {
        const numbInvoice = maxValue.toString().padStart(7, "0");
        setLoading(true);
        let response;
        await formikInvoice.handleSubmit();
        if (id) {
            setSeverity("success");
            setMessage("Votre facture a été bien modifié");
            // for add new articles
            let existingIdArticles = [];
            initialData.forEach(obj => {
                existingIdArticles.push(obj.id);
            })
            const newPieces = datas.map(piece => {
                if (!existingIdArticles.includes(piece.id)) {
                    piece.id = null;
                    return piece
                }
                return piece;
            })

             response = await putInvoice({
                 id: id,
                 nameClient: selectedName.name,
                 total: datas.map(amount).reduce(sum),
                 type: selectedType.value,
                 numbInvoice: numbInvoice,
                 pieces: newPieces,
                 ninea: ninea,
                 invoiceId: id && id.toString().padStart(7, "0"),
                 dateInvoice: dateInvoice,
                 dateDelivery: dateDelivery,
                 mode: mode.toUpperCase(),
                 priceString: formikInvoice.values.stringPrice
             });

        } else {
            setSeverity("success");
            setMessage("Votre facture a été bien ajouté");
            let pieces = [];
            datas.forEach(data => {
                data.id = null;
                pieces.push(data);
            })
            response = await saveInvoice({
                nameClient: selectedName.name,
                total: datas.map(amount).reduce(sum),
                type: selectedType.value,
                numbInvoice: maxValue.toString().padStart(7, "0"),
                pieces: pieces,
                ninea: ninea,
                dateInvoice: dateInvoice,
                dateDelivery: dateDelivery,
                mode: mode.toUpperCase(),
                priceString: formikInvoice.values.stringPrice,
            });

        }
        setOpenAlert("block");
        if (response) {
            if (pdfBase64) {
                const blob = b64toBlob(pdfBase64, 'application/pdf');
                await FileSaver.saveAs(blob, "Facture" + numbInvoice + ".pdf");
            }
            formikInvoice.resetForm();
            formikTable.resetForm();
            setDatas([]);
            setOpen(false)
            setLoading(false);
            return;
        }
        setSeverity("error")
        setMessage("Une erreur a été rencontré lors de l'ajout de la facture.");
        setOpen(false)
        setLoading(false);
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setOpenDialogModify(false);
    };

    const getQuantityPiece = () => {
        let array = [];
        if (selectedArticle) {
            for (let i = 1; i <= selectedArticle.quantity; i++) {
                array.push({label: i, value: i});
            }
            setQuantity(array)
        }
    }

    const getSum = () => {
        if (datas.length === 0) return 0;
        return datas.map(amount).reduce(sum);
    }


    const validationSchemaInvoice = Yup.object({
        name: Yup.object().required("Le nom du client est obligatoire").nullable(),
        type: Yup.object().required("Veuillez renseigner le type de facture").nullable(),
        stringPrice: Yup.string().nullable()
    });

    const formikInvoice = useFormik({
        initialValues: {
            name: null,
            type: null,
            stringPrice: null,
        },
        validationSchema: validationSchemaInvoice,
        onSubmit: async (data) => {
            const response = await previewPdf({
                ninea: ninea,
                nameClient: data.name.name,
                invoiceId: maxValue && maxValue.toString().padStart(7, "0"),
                type: data.type.value,
                datas: datas,
                dateInvoice: dateInvoice,
                dateDelivery: dateDelivery,
                mode: mode.toUpperCase(),
                priceString: data.stringPrice,
                total: getSum()
            });
            setPdfBase64(response);
            setOpen(true);
        }
    });


    const validationSchemaTable = Yup.object({
        article: Yup.object().required("Choisir un article").nullable(),
        quantity: Yup.object().required("Choisir une quantité").nullable(),
        comment: Yup.string().nullable(),
        newPrice: Yup.number()
            .typeError('Uniquement des chiffres')
            .positive("Ce champ doit être positive"),
    });

    const formikTable = useFormik({
        initialValues: {
            article: null,
            quantity: null,
            comment: "",
            newPrice: "",
        },
        validationSchema: validationSchemaTable,
        onSubmit: async (data, {resetForm}) => {
            const maxVal = datas.length === 0 ? 0 : Math.max.apply(Math, datas.map(o => o.id));
            setDatas([...datas, createData(maxVal + 1, data.article.name, data.quantity.value, data.newPrice, data.newPrice * data.quantity.value, data.comment, data.article.reference, data.article.mark.name)]);
            resetForm();
        }
    });

    //Modify article
    const modifyArticle = (id) => {
        const article = datas.find(e => e.id === id);
        setIdChange(id);
        setArticleChange(article.name);
        setReferenceChange(article.reference);
        setCommentChange(article.details);
        setQuantityChange(article.quantity);
        setPriceChange(article.price);
        setOpenDialogModify(true);
    }
    const handleModifyArticle = () => {
        const newdatas = datas.map(e => e.id === idChange ? {
            ...e,
            quantity: Number(quantityChange),
            details: commentChange,
            price: Number(priceChange),
            totalPrice: Number(quantityChange) * Number(priceChange)
        } : e);
        setDatas(newdatas);
        setOpenDialogModify(false);

    };
    const validationAddCustomerSchema = Yup.object({
        newCustomer: Yup.string().required('Le nom est requis'),
    });
    const formikAddCustomer = useFormik({
        initialValues: {newCustomer: ''},
        validationSchema: validationAddCustomerSchema,
        onSubmit: async (data, {resetForm}) => {
            setLoading(true);
            const response = await saveNewCustomer({name: data.newCustomer});
            if (response) {
                setOpenDialog(false);
            }
            await fetchCustomers();
            setLoading(false);
            resetForm();
        }
    })


    return (
        <div className="wrapper ">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Creer une facture"/>
                <div className="content">
                    {loading && loading ?
                        <div className="container d-flex justify-content-center" style={{marginTop: 350 + 'px'}}>
                            <Loading/>
                        </div> :
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <Message
                                            severity={severity}
                                            setOpenAlert={setOpenAlert}
                                            message={message}
                                            openAlert={openAlert}/>
                                        <div className="card-header">
                                            <h4 className="card-title"> Creer une facture</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form">
                                                <div className="form-control">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-2">
                                                            <h6 className="">Nom du client</h6>
                                                            <ReactSelectWidget
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.name}
                                                                isSearchable={true}
                                                                defaultValue={formikInvoice.values.name}
                                                                name="name"
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                onChange={async selected => {
                                                                    setSelectedName(selected)
                                                                    await formikInvoice.setFieldValue("name", selected)
                                                                }}
                                                                data={customers}/>
                                                            {formikInvoice.touched.name && formikInvoice.errors.name ?
                                                                <span
                                                                    style={{color: 'red'}}>{formikInvoice.errors.name}</span> : null}
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h6 className="">Type de facture</h6>
                                                            <ReactSelectWidget
                                                                defaultValue={formikInvoice.values.type}
                                                                onChange={async selected => {
                                                                    setSelectedType(selected)
                                                                    await formikInvoice.setFieldValue("type", selected)
                                                                }}
                                                                data={[
                                                                    {value: "invoice", label: "Facture"},
                                                                    {value: "proformat", label: "Proformat"},
                                                                    {value: "delivery", label: "Livraison"}
                                                                ]}/>
                                                            {formikInvoice.touched.type && formikInvoice.errors.type ?
                                                                <span
                                                                    style={{color: 'red'}}>{formikInvoice.errors.type}</span> : null}
                                                        </div>
                                                        <div className="col-lg-2"/>
                                                        <div className="col-lg-2">
                                                            <h6 className="">La date de la facture</h6>
                                                            <LocalizationProvider dateAdapter={DateAdapter}>
                                                                <DesktopDatePicker
                                                                    label="La date"
                                                                    inputFormat="dd/MM/yyyy"
                                                                    value={dateInvoice}
                                                                    onChange={handleChangeDateInvoice}
                                                                    renderInput={(params) => <TextField
                                                                        size="small" {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className="col-lg-1"/>
                                                        <div className="col-lg-2">
                                                            <h6 className="">La date de livraison</h6>
                                                            <LocalizationProvider dateAdapter={DateAdapter}>
                                                                <DesktopDatePicker
                                                                    label="Date de livraison"
                                                                    inputFormat="dd/MM/yyyy"
                                                                    value={dateDelivery}
                                                                    onChange={handleChangeDateDelivery}
                                                                    renderInput={(params) => <TextField
                                                                        size="small" {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-3">
                                                            <button
                                                                type="button"
                                                                onClick={handleClickOpenDialog}
                                                                className="btn btn-info">Ajouter un client
                                                            </button>
                                                        </div>
                                                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                                                            <DialogTitle>Ajouter un nouveau client</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText>
                                                                    Sur cette action, vous allez enregistrer une
                                                                    un nouveau client. C'est reellement ce
                                                                    que vous voulez?
                                                                </DialogContentText>
                                                                <TextField
                                                                    autoFocus
                                                                    margin="dense"
                                                                    id="newCustomer"
                                                                    label="Donner le nom du client"
                                                                    type="text"
                                                                    fullWidth
                                                                    placeholder="Donner le nom du client"
                                                                    variant="standard"
                                                                    value={formikAddCustomer.values.newCustomer}
                                                                    onChange={formikAddCustomer.handleChange}
                                                                />
                                                                {formikAddCustomer.touched.newCustomer && formikAddCustomer.errors.newCustomer ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formikAddCustomer.errors.newCustomer}</span> : null}
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleCloseDialog}>Annuler</Button>
                                                                <Button
                                                                    onClick={formikAddCustomer.handleSubmit}>Ajouter</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-lg-4">
                                                    <h4 className="card-title">Table des articles</h4>
                                                </div>

                                                <div className="col-lg-6 content">
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            defaultValue={mode}
                                                            aria-label="gender"
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: 150,
                                                                marginTop: 15
                                                            }}
                                                            onChange={e => setMode(e.target.value)}
                                                            name="customized-radios">
                                                            <FormControlLabel value="nothing" control={<Radio/>}
                                                                              label="Rien"/>
                                                            <FormControlLabel value="pay" control={<Radio/>}
                                                                              label="Payé"/>
                                                            <FormControlLabel value="notpay" control={<Radio/>}
                                                                              label="Non payé"/>
                                                            <FormControlLabel value="deliver" control={<Radio/>}
                                                                              label="Livré"/>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                                <div className="col-lg-2 content"
                                                     style={{borderLeft: "1px solid black", marginTop: 15}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                onChange={e => setNinea(e.target.checked)}
                                                                value={ninea}/>} label="Ninea"/>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive" style={{minHeight: 500 + 'px'}}>
                                                <table className="table">
                                                    <thead className=" text-primary">
                                                    <tr>
                                                        <th>Actions</th>
                                                        <th>Nom de l'article</th>
                                                        <th>Marque</th>
                                                        <th>Reférence</th>
                                                        <th>Commentaires</th>
                                                        <th>Quantité</th>
                                                        <th>Prix unique</th>
                                                        <th>Prix total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {datas && datas.map((data) => (
                                                        <tr>
                                                            <td>
                                                                <FontAwesomeIcon icon={faTrash} size="lg"
                                                                                 onClick={() => deleteArticle(data.id)}
                                                                                 style={{color: 'red'}}/>
                                                                <FontAwesomeIcon icon={faEdit} size="lg"
                                                                                 onClick={() => modifyArticle(data.id)}
                                                                                 style={{
                                                                                     color: 'blue',
                                                                                     marginLeft: 14 + 'px'
                                                                                 }}/>
                                                            </td>
                                                            <td>{data.name}</td>
                                                            <td>{data.mark}</td>
                                                            <td>{data.reference}</td>
                                                            <td>{data.details}</td>
                                                            <td>{data.quantity}</td>
                                                            <td>{data.price}</td>
                                                            <td className="text-right">{data.totalPrice}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td>
                                                    <span style={{fontWeight: 'bold', fontSize: 16}}>
                                                        Total: &nbsp;&nbsp;{datas && datas.length === 0 ? 0 + " FCFA" : datas.map(amount).reduce(sum) + " FCFA"}
                                                    </span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <Dialog open={openDialogModify} onClose={handleCloseDialog}>
                                                    <DialogTitle>Modifier un article</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Sur cette action, vous allez modifier un élément de votre
                                                            facture?
                                                        </DialogContentText>
                                                        <div className="row mt-4">
                                                            <input type="hidden" value={idChange}/>
                                                            <div className="col-lg-7">
                                                                <label>Article:</label>
                                                                <input
                                                                    disabled
                                                                    name="comment"
                                                                    value={articleChange}
                                                                    placeholder="Article"
                                                                    onChange={e => setArticleChange(e.target.value)}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <label>Référence:</label>
                                                                <input
                                                                    disabled
                                                                    name="comment"
                                                                    value={referenceChange}
                                                                    placeholder="Référence"
                                                                    onChange={e => setReferenceChange(e.target.value)}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <label>Commentaires:</label>
                                                                <input
                                                                    name="comment"
                                                                    value={commentChange}
                                                                    placeholder="Commentaires"
                                                                    onChange={e => setCommentChange(e.target.value)}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <label>Quantité:</label>
                                                                <input
                                                                    name="comment"
                                                                    type="number"
                                                                    value={quantityChange}
                                                                    placeholder="Quantité"
                                                                    onChange={e => setQuantityChange(e.target.value)}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <label>Prix unique:</label>
                                                                <input
                                                                    name="comment"
                                                                    value={priceChange}
                                                                    placeholder="Prix"
                                                                    onChange={e => setPriceChange(e.target.value)}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleCloseDialog}>Annuler</Button>
                                                        <Button
                                                            onClick={handleModifyArticle}>Modifier</Button>
                                                    </DialogActions>
                                                </Dialog>
                                                <div className="row mb-4"/>
                                                <div className="row mb-3">
                                                    <div className="col-lg-3">
                                                        <div>
                                                            <label>Article:</label>
                                                            <ReactSelectWidget
                                                                isSearchable={true}
                                                                name="article"
                                                                defaultValue={formikTable.values.article}
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.name}
                                                                onChange={async selected => {
                                                                    setSelectedArticle(selected)
                                                                    await formikTable.setFieldValue("newPrice", selected.price);
                                                                    await formikTable.setFieldValue("article", selected);
                                                                    if (selected.quantity > 0) {
                                                                        await formikTable.setFieldValue("quantity", {
                                                                            value: 1,
                                                                            label: 1
                                                                        })
                                                                    }
                                                                }}
                                                                data={piecesCopy}/>
                                                            {formikTable.touched.article && formikTable.errors.article ?
                                                                <span
                                                                    style={{color: 'red'}}>{formikTable.errors.article}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div>
                                                            <label>Quantité:</label>
                                                        </div>
                                                        <div>
                                                            <ReactSelectWidget
                                                                defaultValue={formikTable.values.quantity}
                                                                isSearchable={true}
                                                                name="quantity"
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                onChange={async selected => {
                                                                    setSelectedQuantity(selected)
                                                                    await formikTable.setFieldValue("quantity", selected)
                                                                }}
                                                                data={quantity}/>
                                                            {formikTable.touched.quantity && formikTable.errors.quantity ?
                                                                <span
                                                                    style={{color: 'red'}}>{formikTable.errors.quantity}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div>
                                                            <label>Commentaires:</label>
                                                        </div>
                                                        <input
                                                            name="comment"
                                                            value={formikTable.values.comment}
                                                            placeholder="Commentaires"
                                                            onChange={formikTable.handleChange}
                                                            className="form-control inputdetails"/>
                                                        {formikTable.touched.comment && formikTable.errors.comment ?
                                                            <span
                                                                style={{color: 'red'}}>{formikTable.errors.comment}</span> : null}
                                                    </div>
                                                    <div className="col-lg-2"/>
                                                    <div className="col-lg-3">
                                                        <div>
                                                            <label>Prix:</label>
                                                        </div>
                                                        <input
                                                            name="newPrice"
                                                            value={formikTable.values.newPrice}
                                                            placeholder="Prix"
                                                            onChange={formikTable.handleChange}
                                                            className="form-control inputprice"/>
                                                        {formikTable.touched.newPrice && formikTable.errors.newPrice ?
                                                            <span
                                                                style={{color: 'red'}}>{formikTable.errors.newPrice}</span> : null}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-7">
                                                        <div className="col-lg-3" style={{marginTop: 15}}>
                                                            <button type="button" onClick={formikTable.handleSubmit}
                                                                    className="btn btn-info">Ajouter sur la facture
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <label>Le prix en lettre</label>
                                                        <input
                                                            name="stringPrice"
                                                            value={formikInvoice.values.stringPrice}
                                                            placeholder="Prix en lettre"
                                                            onChange={formikInvoice.handleChange}
                                                            className="form-control inputdetails"/>
                                                        {formikInvoice.touched.stringPrice && formikInvoice.errors.stringPrice ?
                                                            <span
                                                                style={{color: 'red'}}>{formikInvoice.errors.stringPrice}</span> : null}
                                                    </div>
                                                    <div className="col-lg-3" style={{marginTop: 14}}>
                                                        <button
                                                            onClick={formikInvoice.handleSubmit}
                                                            className="btn btn-secondary"
                                                            type="button">
                                                            Visualiser la facture
                                                        </button>
                                                    </div>
                                                    <div className="col-lg-3" style={{marginTop: 14}}>
                                                        <button
                                                            onClick={handleCloseToSave}
                                                            className="btn btn-warning"
                                                            type="button">
                                                            Enregistrer sans visualiser
                                                        </button>
                                                    </div>
                                                    <Dialog
                                                        fullScreen
                                                        open={open}
                                                        onClose={handleClose}
                                                        TransitionComponent={Transition}
                                                    >
                                                        <AppBar sx={{position: 'relative'}}>
                                                            <Toolbar>
                                                                <IconButton
                                                                    edge="start"
                                                                    color="inherit"
                                                                    onClick={handleClose}
                                                                    aria-label="close"
                                                                >
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                                <Typography sx={{ml: 2, flex: 1}} variant="h6"
                                                                            component="div">
                                                                    Quitter
                                                                </Typography>
                                                                <Button autoFocus color="inherit"
                                                                        onClick={handleCloseToSave}>
                                                                    Enregistrer
                                                                </Button>
                                                            </Toolbar>
                                                        </AppBar>
                                                        <div className='pdf-container'>
                                                            <FilePreviewer
                                                                file={{
                                                                    data: pdfBase64,
                                                                    mimeType: 'application/pdf',
                                                                    name: 'sample.pdf'
                                                                }}
                                                            />
                                                        </div>
                                                    </Dialog>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreateInvoice;
import {instance} from "../utils/interceptors";


export const saveMark = async (data) => {
    return instance.post("/marks", {name: data.name});
}

export const getAllMarks = async () => {
    return instance.get("/marks");
}

export const getAllReference = async () => {
    return instance.get("/reference");
}

export const getReferenceByMark = async (data) => {
    return instance.get("/reference?id=" + data.id);
}

export const getReferenceByPieceName = async (data) => {
    return instance.get("/reference/piecebyarticle?&article=" + data.article);
}

export const saveReference = async (data) => {
    return instance.post("/reference", {name: data.name, mark: data.mark});
}

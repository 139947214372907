import {instance} from "../utils/interceptors";


export const login = async (username, password) => {
    return instance.post("/login", {
        username: username,
        password: password,
    });
}

export const logout = () => {
    localStorage.removeItem("user");
};

export const getCurrentUser = () => {
    try {
        return JSON.parse(localStorage.getItem("user"));
    } catch (_) {
        return null;
    }
};


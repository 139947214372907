import React, {useEffect, useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Loading from "../widgets/Loading";
import {useParams} from 'react-router-dom';
import {getLastPiece, getPieceById, modifyPiece, saveNewPiece} from "../services/PieceService";
import {getAllMarks, saveMark} from "../services/MarkService";
import Message from "../widgets/Message";
import ReactSelectWidget from "../widgets/ReactSelectWidget";
import * as Yup from "yup";
import {useFormik} from "formik";

function CreatePiece() {
    const {id} = useParams();
    //Confirmation
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    //Alert
    const [openAlert, setOpenAlert] = useState('none');
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [, setAddMark] = useState('');

    const [marks, setMarks] = useState([]);
    const [lastPieces, setLastPiece] = useState([]);

    const [, setSelectedMark] = useState(null);


    const fetchingMark = async () => {
        const response = await getAllMarks();
        if (response !== null) {
            setMarks(response);
        }
    };

    const fetchingLastPiece = async () => {
        const response = await getLastPiece();
        if (response !== null) {
            setLastPiece(response);
        }
    };
    const fetchingPiece = async (id) => {
        const response = await getPieceById({id: id});
        if (response !== null) {
            await formik.setFieldValue("name", response.name);
            await formik.setFieldValue("quantity", response.quantity);
            await formik.setFieldValue("price", response.price);
            await formik.setFieldValue("mark", response.mark);
            await formik.setFieldValue("reference", response.reference);

        }
    }
    useEffect(() => {
        fetchingPiece(id);
    }, [id]);

    useEffect(() => {
        setLoading(true);
        fetchingMark();
        fetchingLastPiece();
        setLoading(false);
    }, []);


    const handleClickOpenMark = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Le nom est requis'),
        reference: Yup.string().required('La référence est obligatoire').nullable(),
        quantity: Yup.number()
            .typeError('Uniquement des chiffres')
            .required("La quantité est obligatoire")
            .positive("Ce champ doit être positive"),
        price: Yup.number()
            .typeError('Uniquement des chiffres')
            .required('Le prix est obligatoire')
            .positive("Ce champ doit être positive"),
        mark: Yup.object().required("Ce champ est obligatoire").nullable(),
    });

    const validationAddMarkSchema = Yup.object({
        newMark: Yup.string().required('Le nom est requis'),
    });

    async function responseForUpdatingOrSave(response, resetForm, message) {
        if (response !== null) {
            setMessage(message)
            setSeverity("success");
            setOpenAlert("block");
            //reset form
            resetForm();
            await formik.setFieldValue("mark", null);
            await formik.setFieldValue("reference", null);

        } else {
            setMessage("Votre pièce n'a pas été ajouté.")
            setSeverity("error");
            setOpenAlert("block");
        }
    }

    const formikAddMark = useFormik({
        initialValues: {newMark: ''},
        validationSchema: validationAddMarkSchema,
        onSubmit: async (data, {resetForm}) => {
            const response = await saveMark({name: data.newMark});
            if (response) {
                setMarks([]);
                setLoading(true);
                await fetchingMark();
                setLoading(false);
                setOpen(false);
                setAddMark('');
            }
            resetForm();
        }
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            quantity: '',
            price: '',
            mark: '',
            reference: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {resetForm}) => {
            setLoading(true)
            if (id) {
                const response = await modifyPiece({
                    id: id,
                    name: data.name,
                    price: data.price,
                    quantity: data.quantity,
                    mark: data.mark.id,
                    reference: data.reference
                });
                await responseForUpdatingOrSave(response, resetForm, "Cette pièce a été bien modifié.");
            } else {
                const response = await saveNewPiece({
                    name: data.name,
                    price: data.price,
                    quantity: data.quantity,
                    mark: data.mark.id,
                    reference: data.reference
                });
                await responseForUpdatingOrSave(response, resetForm, "Cette pièce a été bien créee.");

            }

            setLoading(false);
        },
    });

    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Contrôle de mes pièces"/>
                <div className="content">
                    {loading && loading ?
                        <div className="container d-flex justify-content-center" style={{marginTop: 350 + 'px'}}>
                            <Loading/>
                        </div> :
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <Message
                                            severity={severity}
                                            setOpenAlert={setOpenAlert}
                                            message={message}
                                            openAlert={openAlert}/>
                                        <div className="card-header">
                                            <h4 className="card-title"> Formulaire d'ajout de nouvelles pièces</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="form-control">
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Nom</h6>
                                                                <input name="name"
                                                                       value={formik.values.name}
                                                                       onChange={formik.handleChange}
                                                                       className="form-control"
                                                                       placeholder="Nom"/>
                                                                {formik.touched.name && formik.errors.name ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.name}</span> : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Quantité</h6>
                                                                <input name="quantity"
                                                                       value={formik.values.quantity}
                                                                       onChange={formik.handleChange}
                                                                       className="form-control"
                                                                       placeholder="Quantité"/>
                                                                {formik.touched.quantity && formik.errors.quantity ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.quantity}</span> : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Prix</h6>
                                                                <input name="price"
                                                                       value={formik.values.price}
                                                                       onChange={formik.handleChange}
                                                                       className="form-control"
                                                                       placeholder="Prix"/>
                                                                {formik.touched.price && formik.errors.price ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.price}</span> : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Reférence</h6>
                                                                <input name="reference"
                                                                       value={formik.values.reference}
                                                                       onChange={formik.handleChange}
                                                                       className="form-control"
                                                                       placeholder="Référence"/>
                                                                {formik.touched.reference && formik.errors.reference ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.reference}</span> : null}
                                                            </div>
                                                        </div>
                                                        {/*Start mark */}
                                                        <div className="row mb-3">
                                                            <div className="col-lg-6">
                                                                <h6 className="">Marque:</h6>
                                                                <ReactSelectWidget
                                                                    isSearchable={true}
                                                                    name="mark"
                                                                    defaultValue={formik.values.mark}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.name}
                                                                    onChange={async selected => {
                                                                        setSelectedMark(selected)
                                                                        await formik.setFieldValue("mark", selected)
                                                                    }}
                                                                    data={marks}/>
                                                                {formik.touched.mark && formik.errors.mark ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.mark}</span> : null}
                                                            </div>
                                                            <div className="col-lg-4" style={{marginTop: 14}}>
                                                                <button
                                                                    name="add-mark"
                                                                    style={{height: 32}}
                                                                    type="button"
                                                                    onClick={handleClickOpenMark}
                                                                    className="btn btn-light p-1">
                                                                    Ajouter une nouvelle marque
                                                                </button>
                                                                <Dialog open={open} onClose={handleClose}>
                                                                    <DialogTitle>Ajouter une nouvelle marque de
                                                                        voiture</DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            Sur cette action, vous allez enregistrer une
                                                                            nouvelle marque de voiture. C'est rellement
                                                                            ce
                                                                            que vous voulez?
                                                                        </DialogContentText>
                                                                        <TextField
                                                                            autoFocus
                                                                            margin="dense"
                                                                            id="newMark"
                                                                            label="Donner la marque"
                                                                            type="text"
                                                                            value={formikAddMark.values.newMark}
                                                                            fullWidth
                                                                            placeholder="Donner le nom de la marque"
                                                                            variant="standard"
                                                                            onChange={formikAddMark.handleChange}
                                                                        />
                                                                        {formikAddMark.touched.newMark && formikAddMark.errors.newMark ?
                                                                            <span
                                                                                style={{color: 'red'}}>{formikAddMark.errors.newMark}</span> : null}

                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={handleClose}>Annuler</Button>
                                                                        <Button
                                                                            onClick={formikAddMark.handleSubmit}>Ajouter</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                        {/*End mark*/}
                                                        <div className="row mb-3">
                                                            <div className="col-lg-1">
                                                                <button
                                                                    className="btn btn-info">
                                                                    Ajouter
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Les 5 dernières pièces.</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead className=" text-primary">
                                                    <tr>
                                                        <th>Nom</th>
                                                        <th>Quantité</th>
                                                        <th>Prix</th>
                                                        <th className="text-right">Marque</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {lastPieces && lastPieces.map(e => (
                                                        <tr>
                                                            <td>{e.name}</td>
                                                            <td>{e.quantity}</td>
                                                            <td>{e.price}</td>
                                                            <td className="text-right">{e.mark.name}</td>
                                                        </tr>
                                                    ))}
                                                    {/* {lastPieces && lastPieces.map(e => (
                                                        <tr>
                                                            <td>{e.name}</td>
                                                            <td>{e.quantity}</td>
                                                            <td>{e.price}</td>
                                                            <td className="text-right">{e.mark}</td>
                                                        </tr>
                                                    ))}*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                </div>
            </div>
        </div>
    );
}

export default CreatePiece;
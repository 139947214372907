import {instance} from "../utils/interceptors";

export const getAllCustomers = async () => {
    return instance.get("/customers/all");
}

export const findCustomer = async (id) => {
    return instance.get("/findCustomer?id=" + id);
}
export const deleteCustomer = async (id) => {
    return instance.delete("/customers?id=" + id);
}

export const saveNewCustomer = async (data) => {
    return instance.post("/saveCustomer", {name: data.name});
}
export const editNameCustomer = async (data) => {
    return instance.put("/customers", {id: data.id, name: data.name});
}

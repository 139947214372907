import React from 'react';
import {Alert} from "@mui/material";

function Message(props) {
    return (
        <div style={{display: props.openAlert}}>
            <Alert severity={props.severity} onClose={() => {
                props.setOpenAlert("none")
            }}>{props.message}</Alert>
        </div>
    );
}

export default Message;
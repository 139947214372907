import React from "react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Customers from "./pages/Customers";
import CreateInvoice from "./pages/CreateInvoice";
import Invoices from "./pages/Invoices";
import CreateUser from "./pages/CreateUser";
import CreatePiece from "./pages/CreatePiece";
import Users from "./pages/Users";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import PrivateRoute from "./routes/PrivateRoute";
import Pieces from "./pages/Pieces";


function App() {

    return (
        <Router>
            <Switch>
                <Route exact path="/"
                       render={() => {
                           return (<Redirect to="/homepage"/>);
                       }}
                />
                <PrivateRoute path="/homepage" component={Dashboard}/>
                <Route path="/login" component={Login}/>
                <PrivateRoute exact path="/customers" component={Customers}/>
                <PrivateRoute exact path="/create-invoice" component={CreateInvoice}/>
                <PrivateRoute path="/create-invoice/:id" component={CreateInvoice}/>
                <PrivateRoute exact path="/invoice" component={Invoices}/>
                <PrivateRoute exact path="/user" component={CreateUser}/>
                <PrivateRoute exact path="/user/:id" component={CreateUser}/>
                <PrivateRoute exact path="/users" component={Users}/>
                <PrivateRoute exact path="/piece" component={CreatePiece}/>
                <PrivateRoute path="/piece/:id" component={CreatePiece}/>
                <PrivateRoute exact path="/pieces" component={Pieces}/>
            </Switch>
        </Router>
    );
}

export default App;
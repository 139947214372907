import React from 'react';

function Card(props) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="card card-stats">
                <div className="card-body ">
                    <div className="row">
                        <div className="col-5 col-md-4">
                            <div className={props.conf}>
                                {props.children}
                            </div>
                        </div>
                        <div className="col-7 col-md-8">
                            <div className="numbers">
                                <p className="card-category">{props.name}</p>
                                <p className="card-title">{props.value}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer ">
                    <hr/>
                    <div className="stats">
                        <i className="fa fa-clock-o"/>A l'instant
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
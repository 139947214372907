import React, {useState} from "react";
import './css/login.css';
import {useFormik} from 'formik';
import {authLogin} from "../actions/auth";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import Message from "../widgets/Message";

export default function Login() {
    const dispatch = useDispatch();
    const history = useHistory();

    //Alert
    const [openAlert, setOpenAlert] = useState('none');
    const [message, setMessage] = useState("");
    const [severity] = useState("warning");


    const validationForm = (data) => {
        let errors = {};
        if (!data.username) {
            errors.username = "Veuillez renseigner ce champ";
        }
        if (!data.password) {
            errors.password = "Veuillez renseigner ce champ";
        }
        return errors;
    }
    const formik = useFormik({
        initialValues: {username: '', password: ''},
        validate: validationForm,
        onSubmit: async (values) => {
            const response = await dispatch(authLogin(values.username, values.password));
            if (response !== null) {
                history.push("/homepage");
            } else {
                setOpenAlert("block");
                setMessage("Verifiez vos identifiants");
            }
        }
    });


    return (
        <div className="container-fluid">
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image"/>
                <div className="col-md-6 bg-light">
                    <Message
                        severity={severity}
                        setOpenAlert={setOpenAlert}
                        message={message}
                        openAlert={openAlert}/>
                    <div className="login d-flex align-items-center py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 col-xl-7 mx-auto">
                                    <h3 className="display-4">Page de connexion</h3>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label>Nom d'utilisateur</label>
                                            <input id="username"
                                                   type="text"
                                                   placeholder="Username"
                                                   autoFocus=""
                                                   value={formik.values.username}
                                                   onChange={formik.handleChange}
                                                   className="form-control rounded-pill border-0 shadow-sm px-4"/>
                                            {formik.touched.username && formik.errors.username ?
                                                <span style={{color: 'red'}}>{formik.errors.username}</span> : null}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Mot de passe</label>
                                            <input id="password"
                                                   type="password"
                                                   placeholder="Mot de passe"
                                                   value={formik.values.password}
                                                   onChange={formik.handleChange}
                                                   className="form-control rounded-pill border-0 shadow-sm px-4"/>
                                            {formik.touched.password && formik.errors.password ?
                                                <span style={{color: 'red'}}>{formik.errors.password}</span> : null}
                                        </div>

                                        <button type="submit"
                                                className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">
                                            Se connecter
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
import React, {useState} from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-loader-spinner";
import {getDataLoading} from "../../utils/utils";
import {useHistory} from "react-router-dom";

function TableUser(props) {

    const history = useHistory();

    const getRole = (value) => {
        if (value === 'ROLE_ADMIN') {
            return "Administrateur";
        } else {
            return "Utilisateur";
        }
    }
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");

    return (
        <div>
            <div className="row pb-3">
                <div className="col-lg-2">
                    <h6 className="">Nom</h6>
                    <input name="name"
                           value={name}
                           onChange={e => setName(e.target.value)}
                           className="form-control"
                           placeholder="Nom"/>
                </div>
                <div className="col-lg-2">
                    <h6 className="">Nom d'utilisateur</h6>
                    <input name="username"
                           value={username}
                           onChange={e => setUsername(e.target.value)}
                           className="form-control"
                           placeholder="Nom d'utilisateur"/>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-lg-4">
                    <span className="text-primary h5">Nombre total d'utilisateurs: </span><span
                    className="h6">{props.size}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 mb-3">
                    <a href={"/users?name=" + name + "&username=" + username}>
                        <button className="btn btn-info">Rechercher</button>
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table">
                        <thead className=" text-primary">
                        <tr>
                            <th>Actions</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Creer le</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getDataLoading(props.data) && props.data?.length !== 0 ? props.data.map(user => {
                            return <tr>
                                <td>
                                    <DeleteIcon
                                        onClick={() => {
                                            props.handleDelete(user.id)
                                        }}
                                        style={{marginRight: 25, color: "red"}}/>
                                    <FontAwesomeIcon
                                        icon={faEllipsisV}
                                        onClick={() => history.push("/user/" + user.id)}/>
                                </td>
                                <td>{user.name}</td>
                                <td>{user.username}</td>
                                <td>{getRole(user?.roleCollection[0]?.name)}</td>
                                <td>{new Date(user?.createdAt ? user?.createdAt : user?.updatedAt).toLocaleDateString()} à {new Date(user?.createdAt ? user?.createdAt : user?.updatedAt).toLocaleTimeString()}</td>
                            </tr>;
                        }) : <tr>
                            <td/>
                            <div className="text-center">
                                <span style={{fontWeight: "bold"}}>Nous chargons les données</span>
                                <span><Spinner animation="grow"/></span>
                            </div>
                            <td/>

                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TableUser;
import React, {useEffect, useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import {deleteCustomer, editNameCustomer, findCustomer, getAllCustomers} from "../services/CustomerService";
import Loading from "../widgets/Loading";
import Confirmation from "../widgets/Confirmation";
import EditDialogCustomer from "../widgets/EditDialogCustomer";
import './css/pagination.css'
import TableList from "../widgets/TableList";
import TableCustomer from "../widgets/tables/TableCustomer";
import {useLocation} from "react-router-dom";


function Customers() {
    const location = useLocation();

    const [customers, setCustomers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [nameCustomer, setNameCustomer] = React.useState("");

    const endpoint = {search: location.search.replace("?", ""), pathname: location.pathname};


    const fetchingAllCustomers = async () => {
        const response = await getAllCustomers();
        if (response !== null) {
            setCustomers(response.content);
        } else {
            //Implement this part later
        }
    }

    useEffect(() => {
        fetchingAllCustomers();
    }, []);

    const handleDelete = (id) => {
        localStorage.setItem("idDeleteCustomer", id);
        setTitle("Suppression d'utilisateur");
        setMessage("Voulez vous réellement supprimer ce client");
        setOpen(true);

    }
    const handleEdit = async (id) => {
        localStorage.setItem("idEditCustomer", id);
        setTitle("Modification du client");
        setMessage("Sur cette boîte dialogue, on permet la modification du nom du client.");
        const response = await findCustomer(id);
        if (response !== null) {
            setNameCustomer(response.name);
        }
        setOpenEdit(true);

    }

    const handleDeleteConfirm = async () => {
        const id = localStorage.getItem("idDeleteCustomer");
        localStorage.removeItem("idDeleteCustomer");
        const response = await deleteCustomer(id);
        if (response) {
            setOpen(false);
            setLoading(true);
            await fetchingAllCustomers();
            setLoading(false);
        }
    }
    const handleEditConfirm = async () => {
        const id = localStorage.getItem("idEditCustomer");
        localStorage.removeItem("idEditCustomer");
        const response = await editNameCustomer({id: id, name: nameCustomer});
        if (response) {
            setOpen(false);
            setLoading(true);
            await fetchingAllCustomers();
        } else {
            //Implement yet
        }
        setOpenEdit(false);
        setLoading(false);

    }

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
    }



    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Mes clients"/>
                <div className="content">
                    {loading && loading ?
                        <div className="container d-flex justify-content-center" style={{marginTop: 350 + 'px'}}>
                            <Loading/>
                        </div> :
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card-header">
                                                    <h4 className="card-title">Le tableau de mes clients</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-5"/>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <TableList
                                                    endpoint={endpoint}
                                                    component={TableCustomer}
                                                    handleDelete={handleDelete}
                                                    handleEdit={handleEdit}/>
                                                <Confirmation
                                                    onClose={handleClose}
                                                    title={title}
                                                    message={message}
                                                    handleConfirm={handleDeleteConfirm}
                                                    open={open}/>
                                                <EditDialogCustomer
                                                    onClose={handleClose}
                                                    title={title}
                                                    name={nameCustomer}
                                                    setNameCustomer={setNameCustomer}
                                                    message={message}
                                                    handleConfirm={handleEditConfirm}
                                                    open={openEdit}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Customers;
import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useSelector} from 'react-redux'

function PrivateRoute({component: Component, isAuthenticated, ...rest}) {
    const isLoggedIn = useSelector(state => state.authState.isLoggedIn)

    const getAuth = () => {
        return isLoggedIn;
    }
    return (
        <Route
            {...rest}
            render={
                ({location}) => (
                    getAuth()
                        ? (
                            <Component {...rest}/>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {from: location}
                                }}
                            />
                        ))
            }
        />
    );
}

export default PrivateRoute;
import React, {useState} from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import Confirmation from "../Confirmation";
import {getDataLoading} from "../../utils/utils";
import Spinner from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

function TableInvoice(props) {
    const history = useHistory();
    const [name, setName] = useState("");
    const [numberInvoice, setNumberInvoice] = useState("");
    return (
        <div>
            <div className="row">
                <div className="col-lg-2">
                    <h6 className="">Nom</h6>
                    <input name="name"
                           value={name}
                           onChange={e => setName(e.target.value)}
                           className="form-control"
                           placeholder="Nom"/>
                </div>
                <div className="col-lg-2">
                    <h6 className="">Numéro facture</h6>
                    <input name="numberInvoice"
                           value={numberInvoice}
                           onChange={e => setNumberInvoice(e.target.value)}
                           className="form-control"
                           placeholder="Numéro"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 mb-3 mt-1">
                    <a href={"/invoice?name=" + name + "&number=" + numberInvoice}>
                        <button className="btn btn-info">Rechercher</button>
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table">
                        <thead className=" text-primary">
                        <tr>
                            <th>Actions</th>
                            <th>Numéro de facture</th>
                            <th>Nom</th>
                            <th>Total</th>
                            <th>Créer par</th>
                            <th>Créer le</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getDataLoading(props.data) ? props.data.map(invoice =>
                            <tr>
                                <td>
                                    <DeleteIcon
                                        onClick={() => props.handleDelete(invoice.id)}
                                        style={{marginRight: 25, color: "red"}}/>
                                    <FontAwesomeIcon
                                        icon={faEllipsisV}
                                        onClick={() => history.push("/create-invoice/" + invoice.id)}/>
                                </td>
                                <td>{invoice.numbInvoice}</td>
                                <td>{invoice.nameCustomer}</td>
                                <td>{invoice.total}</td>
                                <td>{invoice?.user?.name}</td>
                                <td>{new Date(invoice.createdAt ? invoice.createdAt : invoice.updatedAt).toLocaleDateString()} à {new Date(invoice.createdAt ? invoice.createdAt : invoice.updatedAt).toLocaleTimeString()}</td>
                            </tr>
                        ) : <tr>
                            <td/>
                            <td/>
                            <div className="text-center">
                                <span style={{fontWeight: "bold"}}>Nous chargons les données</span>
                                <span><Spinner animation="grow"/></span>
                            </div>
                            <td/>
                            <td/>
                            <td/>

                        </tr>}
                        </tbody>
                        <Confirmation
                            title={props.title}
                            message={props.message}
                            open={props.open}/>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TableInvoice;
import {instance} from "../utils/interceptors";

export const getPieceById = async (data) => {
    return await instance.get("/getPiece?id=" + data.id);
}

export const getLastPiece = async () => {
    return await instance.get("/piece?max=5");
}

export const saveNewPiece = async (data) => {
    return await instance.post("/piece", {
        name: data.name,
        quantity: data.quantity,
        price: data.price,
        mark: data.mark,
        reference: data.reference
    });
}
export const modifyPiece = async (data) => {
    return await instance.put("/piece", {
        id: data.id,
        name: data.name,
        quantity: data.quantity,
        price: data.price,
        mark: data.mark,
        reference: data.reference
    });
}
export const deletePiece = async (data) => {
    return await instance.delete("/piece?id=" + data.id);
}

export const getPieceByMark = async (data) => {
    return await instance.get("/piece/mark?mark=" + data.mark);
}
export const getAllUniqueArticle = async () => {
    return await instance.get('/piece/unique');
}

export const getAllPieces = async ()=>{
    return await  instance.get("/pieces/all")
}

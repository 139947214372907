import {CLEAR_MESSAGE, SET_MESSAGE} from "../actions/type";

const initialState = {};


export const authMessage = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_MESSAGE:
            return {message: payload};

        case CLEAR_MESSAGE:
            return {message: ""};

        default:
            return state;
    }
}
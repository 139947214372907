import axios from "axios";
import store from "../store";
import {authLogout} from "../actions/auth";


const {dispatch} = store; // direct access to redux store.
const API_URL = process.env.REACT_APP_API_URL;

function getLocalAccessToken() {
    const localStore = window.localStorage.getItem("user");
    const user = localStore && JSON.parse(localStore);
    return user && user.access_token;
}

function getLocalRefreshToken() {
    const localStore = window.localStorage.getItem("user");
    const user = localStore && JSON.parse(localStore);
    return user && user.refresh_token;
}

export const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    async res => {
        return await res.data;
    },
    async (err) => {
        const originalConfig = err.config;
        // Access Token was expired
        try {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const response = await instance.post("/token/refresh", {refreshToken: getLocalRefreshToken()});
                    localStorage.setItem("user", JSON.stringify(response));
                    originalConfig.headers.Authorization = "Bearer " + response.access_token;
                    const responseRequest = await axios.request(originalConfig);
                    return responseRequest.data;

                } catch (error) {
                    dispatch(authLogout());
                    return null;
                }
            }
            //Not found
            if (err.response.status === 404) {
                return null;
            }
            //Forbidden
            if (err.response.status === 403 && err.response.data) {
                return null;
            }
            //Bad request
            if (err.response.status === 400 && err.response.data) {
                return null;
            }
            return Promise.reject(err);
        } catch (error) {
            dispatch(authLogout());
        }

    }
);

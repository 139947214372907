import {instance} from "../utils/interceptors";


export const getSummary = async () => {
    return instance.get("/summary");
}
export const getUserById = async (data) => {
    return instance.get("/user?id=" + data.id);
}

export const saveNewUser = async (data) => {
    return instance.post("/user/save", {
        name: data.name,
        username: data.username,
        password: data.password,
        role: data.role.value
    });
}
export const modifyUser = async (data) => {
    return instance.put("/user?id=" + data.id, {
        name: data.name,
        username: data.username,
        password: data.password,
        role: data.role.value
    });
}

export const getAllUsers = async () => {
    return instance.get("/users");
}

export const deleteUser = async (id) => {
    return instance.delete("/users?id=" + id);
}




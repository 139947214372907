import {login, logout} from '../services/AuthService'
import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE} from "./type";


export const authLogin = (username, password) => async (dispatch) => {
    try {
        const response = await login(username, password);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {user: response}
        })
        localStorage.setItem("user", JSON.stringify(response));
        return response;
    } catch (error) {

        dispatch({
            type: LOGIN_FAIL
        });
        dispatch({
            type: SET_MESSAGE,
            payload: "An error is occured, when trying to sign in"
        })
        return null;
    }
}


export const authLogout = () => (dispatch) => {
    logout();
    dispatch({
        type: LOGOUT
    });
}
import React, {useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import {deleteUser} from "../services/UserService";
import Confirmation from "../widgets/Confirmation";
import TableList from "../widgets/TableList";
import TableUser from "../widgets/tables/TableUser";
import {useLocation} from "react-router-dom";

function Users() {
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [deleted, setDeleted] = useState("");

    const endpoint = {search: location.search.replace("?", ""), pathname: location.pathname};


    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        const id = localStorage.getItem("idDelete");
        localStorage.removeItem("idDelete");
        const response = await deleteUser(id);
        if (response) {
            setOpen(false);
            setDeleted("delete" + id + " success");

        }
    };
    const handleDelete = (value) => {
        localStorage.setItem("idDelete", value);
        setTitle("Suppression d'utilisateur");
        setMessage("Voulez vous réellement supprimer cet utilisateur");
        setOpen(true);
    }


    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Les utilisateurs"/>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card-header">
                                                <h4 className="card-title">Le tableau de tous les utilisateurs</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5"/>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <TableList
                                                endpoint={endpoint}
                                                action={deleted}
                                                component={TableUser}
                                                handleDelete={handleDelete}/>
                                        </div>
                                    </div>
                                    <Confirmation
                                        onClose={handleClose}
                                        title={title}
                                        message={message}
                                        handleConfirm={handleConfirm}
                                        open={open}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;
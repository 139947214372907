import React, {useEffect, useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {getUserById, modifyUser, saveNewUser} from "../services/UserService";
import {Alert} from "@mui/material";
import ReactSelectWidget from "../widgets/ReactSelectWidget";
import Loading from "../widgets/Loading";
import {useParams} from "react-router-dom";

function CreateUser() {
    const {id} = useParams();

    const [openAlert, setOpenAlert] = useState("none");
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchUser(id);
    }, []);

    const fetchUser = async (id) => {
        const user = await getUserById({id: id});
        if (user) {
            await formik.setFieldValue("name", user?.name)
            await formik.setFieldValue("username", user?.username)
            if (user.roleCollection[0].name === "ROLE_USER") {
                await formik.setFieldValue("roleName", {value: "ROLE_USER", label: "Utilisateur Simple"});
            } else {
                await formik.setFieldValue("roleName", {value: "ROLE_ADMIN", label: "Administrateur"});
            }
        }
    }


    const validationSchema = Yup.object({
        name: Yup.string().required('Le nom est requis'),
        username: Yup.string()
            .required("Le nom d'utilisateur est requis")
            .min(4, "Minimum 4 caractères")
            .max(10, "Maximum 10 caractères"),
        password: Yup.string()
            .required('Mot de passe obligatoire')
            .min(4, "Minimum 4 caractères")
            .max(10, "Maximum 10 caractères"),
        confirmPassword: Yup.string()
            .required('La confirmation du mot de passe est obligatoire')
            .oneOf([Yup.ref('password'), null], 'Les mots de passes ne correspondent pas'),
        roleName: Yup.object().required("Ce champ est obligatoire").nullable()
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            name: '',
            password: '',
            confirmPassword: '',
            roleName: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {resetForm}) => {
            let response;
            setLoading(true);
            if (id) {
                response = await modifyUser({
                    id: id,
                    name: data.name,
                    password: data.password,
                    role: data.roleName,
                    username: data.username
                });
                setMessage("L'utilisateur a été bien modifié.");
            } else {
                response = await saveNewUser({
                    name: data.name,
                    password: data.password,
                    role: data.roleName,
                    username: data.username
                });
                setMessage("L'utilisateur a été bien enregistré.");
            }

            setLoading(false);
            if (response != null) {
                await formik.setFieldValue("roleName", null);
                resetForm();
                setOpenAlert("block");
                setSeverity("success");

            } else {
                setOpenAlert("block");
                setSeverity("error");
                setMessage("On a rencontré un problème. Assurez vous que le nom d'utilisateur soit unique")
            }
        },
    });


    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Creer un utilisateur"/>
                <div className="content">
                    {loading && loading ?
                        <div className="container d-flex justify-content-center" style={{marginTop: 350 + 'px'}}>
                            <Loading/>
                        </div> :
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div style={{display: openAlert}}>
                                            <Alert severity={severity} onClose={() => {
                                                setOpenAlert("none")
                                            }}>{message}</Alert>
                                        </div>
                                        <div className="card-header">
                                            <h4 className="card-title"> Formulaire d'ajout d'utilisateur</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="form-control">
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Nom et prénom</h6>
                                                                <input
                                                                    name="name"
                                                                    value={formik.values.name}
                                                                    onChange={formik.handleChange}
                                                                    className="form-control"
                                                                    placeholder="Nom et prénom"/>
                                                            </div>
                                                            {formik.touched.name && formik.errors.name ?
                                                                <span
                                                                    style={{color: 'red'}}>{formik.errors.name}</span> : null}
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Nom d'utilisateur</h6>
                                                                <input
                                                                    name="username"
                                                                    value={formik.values.username}
                                                                    onChange={formik.handleChange}
                                                                    className="form-control"
                                                                    placeholder="Nom d'utilisateur"/>
                                                            </div>
                                                            {formik.touched.username && formik.errors.username ?
                                                                <span
                                                                    style={{color: 'red'}}>{formik.errors.username}</span> : null}
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-lg-6">
                                                                <h6 className="">Mot de passe</h6>
                                                                <input
                                                                    type="password"
                                                                    name="password"
                                                                    value={formik.values.password}
                                                                    onChange={formik.handleChange}
                                                                    className="form-control"
                                                                    placeholder="Mot de passe"/>
                                                                {formik.touched.password && formik.errors.password ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.password}</span> : null}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <h6 className="">Confirmer votre mot de passe</h6>
                                                                <input
                                                                    type="password"
                                                                    name="confirmPassword"
                                                                    value={formik.values.confirmPassword}
                                                                    onChange={formik.handleChange}
                                                                    className="form-control"
                                                                    placeholder="Confirmer votre de passe"/>
                                                                {formik.touched.confirmPassword && formik.errors.confirmPassword ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.confirmPassword}</span> : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-7">
                                                                <h6 className="">Role</h6>
                                                                <ReactSelectWidget
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    name="roleName"
                                                                    defaultValue={formik.values.roleName}
                                                                    onChange={selected => {
                                                                        formik.setFieldValue("roleName", selected);
                                                                    }}
                                                                    isSearchable={false}
                                                                    data={[
                                                                        {value: "ROLE_ADMIN", label: "Administrateur"},
                                                                        {
                                                                            value: "ROLE_USER",
                                                                            label: "Utilisateur simple"
                                                                        },
                                                                    ]}/>
                                                                {formik.touched.roleName && formik.errors.roleName ?
                                                                    <span
                                                                        style={{color: 'red'}}>{formik.errors.roleName}</span> : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-1">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-info">
                                                                    Ajouter
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreateUser;
import React, {useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import Loading from "../widgets/Loading";
import TableList from "../widgets/TableList";
import Confirmation from "../widgets/Confirmation";
import TablePiece from "../widgets/tables/TablePiece";
import {deletePiece} from "../services/PieceService";
import {useLocation} from "react-router-dom";

function Pieces() {
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [deleted, setDeleted] = useState("");


    const endpoint = {search: location.search.replace("?", ""), pathname: location.pathname};


    const handleDelete = (value) => {
        localStorage.setItem("idPieceDelete", value);
        setTitle("Suppression d'une pièce");
        setMessage("Voulez vous réellement supprimer cette pièce");
        setOpen(true);
    };
    const handleClose = () => {
        localStorage.removeItem("idPieceDelete");
        setOpen(false);
    };
    const handleDeleteConfirm = async () => {
        const id = localStorage.getItem("idPieceDelete");
        localStorage.removeItem("idDelete");
        setLoading(true);
        const response = await deletePiece({id:id});
        if (response) {
            setOpen(false);
            setLoading(false);
            setDeleted("delete" + id + " success");

        }
    };
    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Mes clients"/>
                <div className="content">
                    {loading && loading ?
                        <div className="container d-flex justify-content-center" style={{marginTop: 350 + 'px'}}>
                            <Loading/>
                        </div> :
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card-header">
                                                    <h4 className="card-title">Le tableau des pièces.</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-5"/>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <TableList
                                                    endpoint={endpoint}
                                                    action={deleted}
                                                    component={TablePiece}
                                                    handleDelete={handleDelete}/>
                                                <Confirmation
                                                    onClose={handleClose}
                                                    title={title}
                                                    message={message}
                                                    handleConfirm={handleDeleteConfirm}
                                                    open={open}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Pieces;
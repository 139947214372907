import {instance} from "../utils/interceptors";

export const saveInvoice = async (data) => {
    return await instance.post('/invoice', {
        nameClient: data.nameClient,
        type: data.type,
        total: data.total,
        pieces: data.pieces,
        numbInvoice: data.numbInvoice,
        dateInvoice: data.dateInvoice,
        dateDelivery: data.dateDelivery,
        mode: data.mode,
        priceString: data.priceString,
        ninea: data.ninea
    });
}
export const putInvoice = async (data) => {
    return await instance.put('/invoice', {
        nameClient: data.nameClient,
        id: data.id,
        type: data.type,
        total: data.total,
        pieces: data.pieces,
        numbInvoice: data.numbInvoice,
        ninea: data.ninea,
        dateInvoice: data.dateInvoice,
        dateDelivery: data.dateDelivery,
        mode: data.mode,
        priceString: data.priceString
    });
}

export const getInvoiceById = async (data) => {
    return await instance.get('/invoice/findInvoice?id=' + data.id);

}

export const getAllInvoice = async () => {
    return await instance.get('/invoice');
}
export const previewPdf = async (data) => {
    return await instance.post('/invoice/preview', {
        nameClient: data.nameClient,
        type: data.type,
        total: data.total,
        pieces: data.datas,
        numbInvoice: data.invoiceId,
        dateInvoice: data.dateInvoice,
        dateDelivery: data.dateDelivery,
        mode: data.mode,
        priceString: data.priceString,
        ninea: data.ninea
    });
}

export const deleteInvoice = async (id) => {
    return await instance.delete('/invoice?id=' + id);
}
export const getMaxValue = async () => {
    return await instance.get('/invoice/findMaxValue');
}

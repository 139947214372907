import React from 'react';
import Select from "react-select";

function ReactSelectWidget(props) {
    return (
        <Select
            className={props.className}
            classNamePrefix={props.classNamePrefix}
            value={props.defaultValue}
            ref={props.reference}
            onChange={props.onChange}
            isSearchable={props.isSearchable}
            name={props.name}
            getOptionLabel={props.getOptionLabel}
            getOptionValue={props.getOptionValue}
            options={props.data}
        />
    );
}

export default ReactSelectWidget;
import React, {useEffect, useState} from 'react';
import {getAllByPagingAndSorting} from "../services/PagingService";
import NavPagination from "./NavPagination";

function TableList({component: Component, ...props}) {

    const [currentPage, setCurrentPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [recordPerPage] = useState(10);

    const getDataPagination = async (currentPage) => {
        currentPage = currentPage - 1;
        const response = await getAllByPagingAndSorting(props.endpoint, currentPage, recordPerPage);
        if (response !== null) {
            setData(response.content);
            setTotalPages(response.totalPages);
            setTotalItems(response.totalElements);
            setCurrentPage(response.number + 1);
        }
    };
    useEffect(() => {
        getDataPagination();
    }, [props.action]);
    const handlePageClick = async (pageSelected) => {
        await getDataPagination(pageSelected);
    };
    return (
        <div>
            <Component
                handleEdit={props.handleEdit}
                handleDelete={props.handleDelete}
                size={totalItems}
                data={data}/>
            <NavPagination
                handleClick={handlePageClick}
                totalPages={totalPages}
                currentPage={currentPage}/>
        </div>
    );
}

export default TableList;
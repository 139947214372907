import React from 'react';
import Loading from "./Loading";
import {Alert} from "@mui/material";

function AlertPiece(props) {
    const getData = (data) => {
        return !!data;
    }
    const getColor = (value) => {
        if (value < 2) {
            return "error";
        } else if ((2 <= value) && (value < 4)) {
            return "warning";
        }else {
            return "info";
        }
    }
    return (
        <div className="col-md-12">
            <div className="card ">
                <div className="card-header ">
                    <h5 className="card-title">Alerte sur les pieces</h5>
                    <p className="card-category">24 Hours performance</p>
                </div>
                <div className="card-body">
                    {getData(props.data) ? props.data.map(e => (
                        <Alert className="mb-1" variant="outlined" severity={getColor(e.quantity)}>
                            Il vous reste {e.quantity} pièce(s) sur votre stock de {e.name}
                        </Alert>
                    )) : (<div className="d-flex justify-content-center">
                        <Loading/>
                    </div>)}
                </div>
                <div className="card-footer ">
                    <hr/>
                    <div className="stats">
                        <i className="fa fa-history"/> A l'instant
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AlertPiece;
import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {TextField} from "@mui/material";

function EditDialogCustomer(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                style={{color: "orange"}}
                id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    defaultValue={props.name}
                    id="name"
                    label="Nom du client"
                    type="email"
                    onChange={e=>props.setNameCustomer(e.target.value)}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}>Annuler</Button>
                <Button
                    onClick={props.handleConfirm}
                    style={{color: "orange"}}
                    autoFocus>
                    Modifier
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditDialogCustomer;
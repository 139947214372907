import React, {useState} from 'react';
import {getDataLoading} from "../../utils/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-loader-spinner";
import {useHistory} from "react-router-dom";


function TablePiece(props) {
    const history = useHistory();
    const [name, setName] = useState("");
    const [mark, setMark] = useState("");
    const [reference, setReference] = useState("");

    return (
        <div>
            <div className="row pb-3">
                <div className="col-lg-2">
                    <h6 className="">Nom</h6>
                    <input name="name"
                           defaultValue={name}
                           onChange={e => setName(e.target.value)}
                           className="form-control"
                           placeholder="Nom"/>
                </div>
                <div className="col-lg-2">
                    <h6 className="">Marque</h6>
                    <input name="mark"
                           defaultValue={mark}
                           onChange={e => setMark(e.target.value)}
                           className="form-control"
                           placeholder="Marque"/>
                </div>
                <div className="col-lg-2">
                    <h6 className="">Référence</h6>
                    <input name="reference"
                           defaultValue={reference}
                           onChange={e => setReference(e.target.value)}
                           className="form-control"
                           placeholder="Référence"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 mb-3">
                    <a href={"/pieces?name=" + name + "&mark=" + mark + "&reference=" + reference}>
                        <button className="btn btn-info">Rechercher</button>
                    </a>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-lg-4">
                    <span className="text-primary h5">Nombre total de pieces: </span><span className="h6">{props.size}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table">
                        <thead className=" text-primary">
                        <tr>
                            <th>Actions</th>
                            <th>Nom</th>
                            <th>Quantité</th>
                            <th>Prix</th>
                            <th>Marque</th>
                            <th>Référence</th>
                            <th>Creer le</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getDataLoading(props.data) ? props.data.map(e => (
                            <tr>
                                <td>
                                    <DeleteIcon
                                        onClick={() => props.handleDelete(e.id)}
                                        style={{marginRight: 25, color: "red"}}/>
                                    <FontAwesomeIcon
                                        icon={faEllipsisV}
                                        onClick={() => history.push("/piece/" + e.id)}/>

                                </td>
                                <td>{e.name}</td>
                                <td>{e.quantity}</td>
                                <td>{e.price}</td>
                                <td>{e.mark.name}</td>
                                <td>{e.reference}</td>
                                <td>{new Date(e.createdAt ? e.createdAt : e.updatedAt).toLocaleDateString()} à {new Date(e.createdAt ? e.createdAt : e.updatedAt).toLocaleTimeString()}</td>
                            </tr>
                        )) : <tr>
                            <td/>
                            <td/>
                            <td/>
                            <div className="text-center">
                                <span style={{fontWeight: "bold"}}>Nous chargons les données</span>
                                <span><Spinner animation="grow"/></span>
                            </div>
                            <td/>
                            <td/>
                            <td/>

                        </tr>}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}

export default TablePiece;
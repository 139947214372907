import React, {useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import {deleteInvoice} from "../services/InvoiceService";
import Confirmation from "../widgets/Confirmation";
import TableList from "../widgets/TableList";
import TableInvoice from "../widgets/tables/TableInvoice";
import {useLocation} from "react-router-dom";

function Invoices() {
    const location = useLocation();

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [deleted, setDeleted] = useState("");

    const endpoint = {search: location.search.replace("?", ""), pathname: location.pathname};


    const handleDeleteConfirm = async () => {
        const id = localStorage.getItem("idDeleteInvoice");
        localStorage.removeItem("idDeleteInvoice");
        const response = await deleteInvoice(id);
        if (response) {
            setOpen(false);
            setDeleted("delete" + id + " success");
        }
    }

    const handleClose = () => {
        setOpen(false);
    }
    const handleDelete = (id) => {
        localStorage.setItem("idDeleteInvoice", id);
        setTitle("Supprimer une facture");
        setMessage("Etês vous sûr de vouloir supprimer cette facture?");
        setOpen(true);
    }

    return (
        <div className="wrapper">
            <Sidebar/>
            <div className="main-panel overflow-auto" style={{height: 100 + 'vh'}}>
                <Navbar page="Liste de mes factures"/>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card-header">
                                                <h4 className="card-title">Mes factures</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5"/>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <TableList
                                                endpoint={endpoint}
                                                action={deleted}
                                                component={TableInvoice}
                                                handleDelete={handleDelete}/>
                                        </div>
                                    </div>
                                    <Confirmation
                                        onClose={handleClose}
                                        title={title}
                                        message={message}
                                        handleConfirm={handleDeleteConfirm}
                                        open={open}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Invoices;
import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    faChartLine,
    faFileInvoiceDollar,
    faPlusSquare,
    faTools,
    faUserAlt,
    faUsers,
    faUserShield,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Sidebar(props) {

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const {pathname} = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <div>
            <div className="sidebar" data-color="white" data-active-color="danger">
                <div className="logo">
                    <a href="https://www.creative-tim.com" className="simple-text logo-mini">
                        <div className="logo-image-small">
                            <img src="../logo.jpg"/>
                        </div>
                    </a>
                    <a href="https://www.creative-tim.com" className="simple-text logo-normal">
                        Moussa DIAKHATE
                    </a>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        <li className={splitLocation[1] === "homepage" ? "active" : ""}>
                            <Link to="/homepage">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faChartLine} size="lg"/>
                                    </div>
                                    <p>Tableau de bord</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "customers" ? "active" : ""}>
                            <Link to="/customers">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faUsers} size="lg"/>
                                    </div>
                                    <p>Liste clients</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "piece" ? "active" : ""}>
                            <Link to="/piece">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faWrench} size="lg"/>
                                    </div>
                                    <p>Ajouter une pièce</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "pieces" ? "active" : ""}>
                            <Link to="/pieces">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faTools} size="lg"/>
                                    </div>
                                    <p>Liste pieces</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "create-invoice" ? "active" : ""}>
                            <Link to="/create-invoice">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faPlusSquare} size="lg"/>
                                    </div>
                                    <p>Ajouter une facture</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "invoice" ? "active" : ""}>
                            <Link to="/invoice">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg"/>
                                    </div>
                                    <p>Liste factures</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "user" ? "active" : ""}>
                            <Link to='/user'>
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faUserAlt} size="lg"/>
                                    </div>
                                    <p>Ajouter utilisateur</p>
                                </div>
                            </Link>
                        </li>
                        <li className={splitLocation[1] === "users" ? "active" : ""}>
                            <Link to="/users">
                                <div className="d-flex">
                                    <div style={{marginRight: 8 + 'px'}}>
                                        <FontAwesomeIcon icon={faUserShield} size="lg"/>
                                    </div>
                                    <p>Liste utilisateurs</p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    );
}

export default Sidebar;
import React from 'react';
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css"; //for css

function NavPagination(props) {
    return (
        <div className="pagination-container d-flex justify-content-lg-end">
            <Pagination
                currentPage={props.currentPage}
                totalPages={props.totalPages}
                changeCurrentPage={props.handleClick}
                theme="bottom-border"
            />
        </div>
    );
}

export default NavPagination;
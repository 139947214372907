import React, {useState} from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-loader-spinner";
import {getDataLoading} from "../../utils/utils";

function TableCustomer(props) {

    const [name, setName] = useState();

    return (
        <div>
            <div className="row">
                <div className="row">
                    <div className="col-lg-2">
                        <h6 className="">Nom</h6>
                        <input name="name"
                               value={name}
                               onChange={e => setName(e.target.value)}
                               className="form-control"
                               placeholder="Nom"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mb-3 mt-1">
                        <a href={"/customers?name=" + name}>
                            <button className="btn btn-info">Rechercher</button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-lg-4">
                    <span className="text-primary h5">Nombre total de clients: </span><span className="h6">{props.size}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table">
                        <thead className=" text-primary">
                        <tr>
                            <th>Actions</th>
                            <th>Name</th>
                            <th>Creer le</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getDataLoading(props.data) ? props.data.map(e => (
                            <tr>
                                <td>
                                    <DeleteIcon
                                        onClick={() => props.handleDelete(e.id)}
                                        style={{marginRight: 25, color: "red"}}/>
                                    {props.handleEdit && (<FontAwesomeIcon
                                        icon={faEllipsisV}
                                        onClick={() => props.handleEdit(e.id)}/>)}

                                </td>
                                <td>{e.name}</td>
                                <td>{new Date(e?.createdAt ? e?.createdAt : e?.updatedAt).toLocaleDateString()} à {new Date(e?.createdAt ? e?.createdAt : e?.updatedAt).toLocaleTimeString()}</td>
                            </tr>
                        )) : <tr>
                            <td/>
                            <div className="text-center">
                                <span style={{fontWeight: "bold"}}>Nous chargons les données</span>
                                <span><Spinner animation="grow"/></span>
                            </div>
                            <td/>

                        </tr>}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TableCustomer;
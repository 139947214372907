import React, {useEffect, useState} from 'react';
import Sidebar from "../widgets/Sidebar";
import Navbar from "../widgets/Navbar";
import Card from "../widgets/Card";
import AlertPiece from "../widgets/AlertPiece";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollarSign, faTools, faUsers, faUserShield} from '@fortawesome/free-solid-svg-icons';
import {getSummary} from "../services/UserService";
import Loading from "../widgets/Loading";


function Dashboard(props) {
    const [summary, setSummary] = useState();
    const getData = (data) => {
        return !!data;
    }

    const fetchSummary = async () => {
        const response = await getSummary();
        if (response != null) {
            setSummary(response);
        }
    }

    useEffect(() => {
        fetchSummary();
    }, []);

    const getSum = (value) => {
        if (summary) {
            if (summary[value]) {
                return summary[value];
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    return (
        <div className="wrapper ">
            <Sidebar/>
            <div className="main-panel">
                <Navbar page="Tableau de bord"/>
                <div className="content">
                    <div className="row">

                        <Card
                            name="Total factures"
                            conf="icon-big text-center text-danger"
                            value={getData(summary)?getSum("totalInvoice"):(<Loading/>)}>
                            <FontAwesomeIcon icon={faDollarSign}/>
                        </Card>
                        <Card
                            name="Utilisateurs"
                            conf="icon-big text-center text-warning"
                            value={getData(summary)?getSum("totalUser"):(<Loading/>)}>
                            <FontAwesomeIcon icon={faUserShield}/>
                        </Card>
                        <Card
                            name="Clients"
                            conf="icon-big text-center text-danger"
                            value={getData(summary)?getSum("totalCustomer"):(<Loading/>)}>
                            <FontAwesomeIcon icon={faUsers}/>
                        </Card>
                        <Card
                            name="Pieces"
                            conf="icon-big text-center text-danger"
                            value={getData(summary)?getSum("totalPiece"):(<Loading/>)}>
                            <FontAwesomeIcon icon={faTools}/>
                        </Card>
                        <Card
                            name="Total sur le mois"
                            conf="icon-big text-center text-info"
                            value={getData(summary)?getSum("buyByMonth"):(<Loading/>)}>
                            <FontAwesomeIcon icon={faDollarSign}/>
                        </Card>
                    </div>
                    <div className="row">
                        <AlertPiece data={summary && summary.alertPiece}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;